import React, { useState } from 'react';
import "./PopupGetFiscalReceive.css";

const PopUpGetFiscalReceive = (props) => {

    return (
         <div className='popup'>           
            <div className='popup_inner'>
                <div className="popup_header">  
                    <h4>Emisión de factura</h4>
                </div>
                <div className="popup_body">  
                    <br></br>
                    RFC para facturar
                    <select class="form-select" aria-label="Default select example" onChange={props.onChangeClientSatSeleted}>
                        <option value="0" selected>Seleeciona una opcion</option>
                        {
                            props.stateClientSatList && props.stateClientSatList?.map(item => 
                                <option key={item.id} value={item.id}>{item.rfc}</option>
                            )
                        }                       
                    </select>
                    <br></br>
                    Comercio : {props.stateSaleSelected.nombreComercial}
                    <br></br>
                    e-business : {props.stateSaleSelected.fGebusinessuuid} 
                    <br></br>   
                    e-product : {props.stateSaleSelected.fGeproductuuid}
                    <br></br>
                    e-customer : {props.stateECustomerFG}    
                    <br></br>
                    <br></br>
                    <p>{props.stateResponseCfdiEmmitFG}</p>

                </div>
                <div className="sectionBtnClose">
                    <button className="btn btn-warning" onClick={e => props.onClickDomEventbtnEmitReceiveFG(e)}>Emite factura</button>                  
                    <button className="btn btn-warning" onClick={e => props.onClickDomEventbtnClosefrompopupFG(e)}>Cerrar</button>
                </div>
            </div>
        </div>         
    )
}


export default PopUpGetFiscalReceive;
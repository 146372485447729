import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { clientService } from '../../Services/clientService';
import { clientSatService } from '../../Services/clientSatService';
import { fiscalRegimeService } from '../../Services/fiscalRegimeService';

import { Common } from '../../Utils/Common';

import swal from 'sweetalert';

const ClientEdit = () => {
    const auth = Common.getUser();  
        
    const navigate =  useNavigate();   

    const [stateClient, setStateClient] = useState({
        id: 0,
        name: '',
        email: 0,
        phone: '',
        ssoId: auth.id,
        numberAddress: '',
        postalCodeAddress: '',
        streetAddress: ''
    });    

    const [stateClientSat, setStateClientSat] = useState({
        id: auth.id,       
        name: '',
        rfc:'',
        alias:'',
        email:'',
        phone:'',
        regime:'',
        cfdiUse:'',
        street:'',
        suburb:'',
        zip:''
    });

    const [stateClientSatList, setStateClientSatList] = useState([]);
    const [ stateFiscalRegime, setStateFiscalRegime] = useState([]);
    const [selectedValueRadio, setSelectedValueRadio] = useState("pf");
    
    useEffect(() => {
        //console.log(auth);   
        
        setStateClient(auth);

        clientSatService.GetAllBySecurityBuyerIdAsync(auth?.token, auth.id).then(response => {           
            setStateClientSatList(response.data);           
        });      
    }, []);

    const onChangehandleChange = (event) => {
        const {name, value} = event.target;
        setStateClient((prevState) => ({
            ...prevState,
            [name]: value
        }));        
    }

    const onchangehandleClientSat = (event) => {
        const {name, value} = event.target;
        setStateClientSat((prevState) => ({
            ...prevState,
            [name]: value
        }));  
    }

    const onClickbtnSaveUpdate = () => {       
        clientService.put(auth.token,stateClient.id, stateClient).then(response => {
            swal("Actualización exitosa!", "tu información ha sido actualizada!", "success")
            .then((value) => {
                // Redireccionar
                navigate('/login', { replace: true });
            }); 
        }).catch(error => {
            setLoading(false);
            console.log(error.response);

            setErrorL(error);      
        });
    }

    const onClickbtnSaveFiscalInfo = () => {
        clientSatService.post(auth.token, stateClientSat).then(response => {
            swal("Alta exitosa!", "tu información fiscal ha sido dada de alta!", "success")
            .then((value) => {
                clientSatService.GetAllBySecurityBuyerIdAsync(auth?.token, auth.id).then(response => {           
                    setStateClientSatList(response.data);           
                });                
            }); 
        }).catch(error => {            
            console.log(error.response);

            setErrorL(error);      
        });
        
    }

    const onClickbtnCancel = (e) => {
        e.preventDefault();

        try {
          navigate('/home', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const handleRadioChange = (value) => {
        setSelectedValueRadio(value);

        switch(value){
            case 'pf':                
                fiscalRegimeService.GetAllFisicaAsync(auth.token).then(response => {
                    setStateFiscalRegime([]);
                    setStateFiscalRegime(response.data);         
                  
                });
                break;
            case 'pm':                
                fiscalRegimeService.GetAllMoralAsync(auth.token).then(response => {
                    setStateFiscalRegime([]);
                    setStateFiscalRegime(response.data);       
                    
                });
                break;
        };
    };

    const onChangehandleChangeSelect = (event) => {
        
        setStateClientSat({
            ...stateClientSat,
            regime: event.target.value
        });        

       
    }

    return (
        <>
            <div className="container">
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Editar Cliente</h2>
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>                
                <h5>Información general</h5>
                <hr></hr>                  
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Informacón general</button>
                        <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Información fiscal</button>
                        
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="userName" className="form-label">Nombre</label>
                                <input className="form-control" placeholder="" required="" disabled
                                    id="userName"
                                    name="userName"
                                    type="text"                            
                                    value={stateClient.userName}            
                                />                                  
                            </div>
                            <div className="col">
                                <label htmlFor="user" className="form-label">Email</label>
                                <input className="form-control" placeholder="" required="" disabled
                                    id="user"
                                    name="user"
                                    type="text"                            
                                    value={stateClient.user}              
                                />
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="phone" className="form-label">Telefono</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    onChange={onChangehandleChange}
                                    value={stateClient.phone}              
                                />                                    
                            </div>
                            <div className="col">                    
                            </div>
                        </div>
                        <br></br>
                        <h5>Dirección</h5>                
                        <hr></hr>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="numberAddress" className="form-label">Número</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="numberAddress"
                                    name="numberAddress"
                                    type="text"
                                    onChange={onChangehandleChange}
                                    value={stateClient.numberAddress}              
                                />                                    
                            </div>
                            <div className="col">    
                                <label htmlFor="postalCodeAddress" className="form-label">Código postal</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="postalCodeAddress"
                                    name="postalCodeAddress"
                                    type="text"
                                    onChange={onChangehandleChange}
                                    value={stateClient.postalCodeAddress}              
                                />      
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="streetAddress" className="form-label">Calle</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="streetAddress"
                                    name="streetAddress"
                                    type="text"
                                    onChange={onChangehandleChange}
                                    value={stateClient.streetAddress}              
                                />                                    
                            </div>
                            <div className="col">    
                                
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="userNameSat" className="form-label">Nombre</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="userNameSat"
                                    name="name"
                                    type="text"                  
                                    value={stateClientSat.name}
                                    onChange={(e) => onchangehandleClientSat(e)}            
                                />                                  
                            </div>
                            <div className="col">
                                <label htmlFor="aliasSat" className="form-label">Alias</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="aliasSat"
                                    name="alias"
                                    type="text"      
                                    value={stateClientSat.alias}
                                    onChange={(e) => onchangehandleClientSat(e)}  
                                />                                
                            </div>
                            <div className='col'>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="rfcSat" className="form-label">Rfc</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="rfcSat"
                                    name="rfc"
                                    type="text"                  
                                    value={stateClientSat.rfc}
                                    onChange={(e) => onchangehandleClientSat(e)}  
                                />                                  
                            </div>
                            <div className="col">
                                <label htmlFor="emailSat" className="form-label">Email</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="emailSat"
                                    name="email"
                                    type="text"     
                                    value={stateClientSat.email}
                                    onChange={(e) => onchangehandleClientSat(e)}                                             
                                />                                
                            </div>
                            <div className="col">
                                <label htmlFor="phoneSat" className="form-label">Telefono</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="phoneSat"
                                    name="phone"
                                    type="text"
                                    value={stateClientSat.phone}
                                    onChange={(e) => onchangehandleClientSat(e)}  
                                />                                  
                            </div>
                            
                        </div>                   
                        
                        <div className="row">
                            <div className="col">
                                <label htmlFor="streetSat" className="form-label">Calle</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="streetSat"
                                    name="street"
                                    type="text"
                                    value={stateClientSat.street}
                                    onChange={(e) => onchangehandleClientSat(e)}  
                                />                                  
                            </div>
                            <div className="col">
                                <label htmlFor="subirbSat" className="form-label">Suburbio</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="subirbSat"
                                    name="suburb"
                                    type="text"    
                                    value={stateClientSat.suburb}
                                    onChange={(e) => onchangehandleClientSat(e)}  
                                />                                
                            </div>
                            <div className="col">
                                <label htmlFor="zipSat" className="form-label">Codigo Postal</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="zipSat"
                                    name="zip"
                                    type="text"
                                    value={stateClientSat.zip}
                                    onChange={(e) => onchangehandleClientSat(e)}  
                                />                                  
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className='col' style={{marginTop: '20px'}}>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="pf" id="pf" checked={ selectedValueRadio === "pf" }
                                        onChange={() => handleRadioChange("pf")}/>
                                    <label class="form-check-label" for="pf">
                                        Persona fisica
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="pm" id="pm" checked={ selectedValueRadio === "pm" }
                                        onChange={() => handleRadioChange("pm")}/>
                                    <label class="form-check-label" for="pm">
                                        Persona moral
                                    </label>
                                </div>
                            </div>
                            <div className="col">
                                {/* <label htmlFor="regimeSat" className="form-label">Regimen fiscal</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="regimeSat"
                                    name="regime"
                                    type="text"
                                    value={stateClientSat.regime}
                                    onChange={(e) => onchangehandleClientSat(e)}  
                                />                                   */}
                                <label htmlFor="selectregime" className="form-label">Regimen fiscal</label>
                                <select class="form-select" aria-label="Default select example" id="regime"
                                    name='regime'
                                    value={stateClientSat.regime}
                                    onChange={ onChangehandleChangeSelect }
                                >
                                    <option selected>Selecciona una opción</option>
                                    {
                                        stateFiscalRegime.map((item, index) => 
                                            <option key={index} value={item.regimeFiscal}>{item.regimeFiscal + '-' + item.description}</option>                                   
                                        )
                                    }
                                </select>
                            </div>
                            <div className="col">
                                <label htmlFor="usecfdiSat" className="form-label">uso Cfdi</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="usecfdiSat"
                                    name="cfdiUse"
                                    type="text"  
                                    value={stateClientSat.cfdiUse} 
                                    onChange={(e) => onchangehandleClientSat(e)}  
                                />                                
                            </div>
                           
                        </div>       
                        <div className='row'>
                            <div className='col' style={{marginTop: '30px'}}>
                               <button style={{backgroundColor:'#FD9913', color:'white'}} type="submit" className="w-100 btn btn-success" onClick={e => onClickbtnSaveFiscalInfo(e)}>Alta de Facturación</button>    
                           </div>
                        </div>                
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>                        
                            <th scope="col">Nombre</th>
                            <th scope="col">Rfc</th>
                            <th scope="col">Regimen</th>
                            <th scope="col">E-Customer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            stateClientSatList && stateClientSatList?.map(item => 
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.rfc}</td>
                                    <td>{item.regime}</td>
                                    <td>{item.fGecustomeruuid}</td>        
                                </tr>                                
                            )
                        }                                           
                    </tbody>
                </table>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button style={{backgroundColor:'#FD9913', color:'white'}} type="submit" className="w-100 btn btn-success" onClick={e => onClickbtnSaveUpdate(e)}>Actualizar información</button>    
                    </div>
                    <div className='col'>
                        <button style={{backgroundColor:'#0D6EFD', color:'white'}} type="button" className="w-100 btn btn-secondary" onClick={e => onClickbtnCancel(e)}>Cancelar</button>                        
                    </div>                        
                </div>
            </div>                   
        </>
    )
}

export default ClientEdit;
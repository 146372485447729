import axios from 'axios';
import Config from "../../config.json";

const API_BASE_URL = `${Config.REACT_APP_APIREST_INVOICE_URL}/v1/FacturaGreen`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',       
    }
};    

const postCustomerAddAsync = async (values) => {       

    const response = await axios.post(  `${API_BASE_URL}/CustomerAddAsync`, values , CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}

const postcfdiemmitAsync = async (values) => {       

    console.log(JSON.stringify(values));

    const response = await axios.post(  `${API_BASE_URL}/cfdiemmitAsync`, values , CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}


export const facturaGreenService = {   
    postCustomerAddAsync,
    postcfdiemmitAsync
}
import React, { useState } from 'react';

import swal from 'sweetalert';

import { salesService } from '../../Services/salesService';

import { Common } from '../../Utils/Common'

import "./popupFilter.css";

const PopUpFilter = (props) => {   
  
    
    return (
        <div className='popup'>           
            <div className='popup_inner'>
                <div className="popup_header">  
                    Filtro de compras
                </div>
                <div className="popup_body">                                                      
                    <br></br>
                    <br></br>                    
                    <div className='row'>
                        <div className='col' style={{textAlign:'center'}}>
                            <select class="form-select" aria-label="Default select example" onChange={props.onChangeSaleStatusSeleted}>
                                <option value="0" selected>Seleeciona una opcion</option>
                                <option value="1">Todas</option>
                                <option value="2">Activa</option>
                                <option value="3">Liquidada</option>
                                <option value="4">Cancelada</option>
                            </select>
                        </div>                        
                    </div>                        
                </div>
                <div className="sectionBtnClose">
                    <button className="btn btn-warning" onClick={props.onClickDomEventbtnClosefrompopup}>Aplicar filtro</button>
                </div>
            </div>
        </div>         
    )
}

export default PopUpFilter;
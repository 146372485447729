import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import PopUpcodeBarPay from './Popupcodebar';
import PopUpGetFiscalReceive from './PopupGetFiscalReceive';

import { cxcService } from '../../Services/Cxc/cxcService';
import { clientSatService } from '../../Services/clientSatService';
import {facturaGreenService} from '../../Services/Invoice/facturaGreenService';

import { CxcCfdiEmmitService } from '../../Services/Cxc/cxccfdiemmitService';

import { Common }from '../../Utils/Common';
import swal from 'sweetalert';

const SaleCxcEdit = () => {    
    const auth = Common.getUser();    
    const { id } = useParams();

    moment.locale("mx");

    const [stateSeenPopup, setstateSeenPopup] = useState(false);
    const [stateSaleSelected, setStateSaleSelected] = useState({
        clienteId:0,
        comercioId: 0,
        cxcList: null,descripcion: '',
        email: '',       
        estatusVentaId: 0,        
        fechaVta: '',
        montoAdelanto: 0,        
        montoVenta: 0,
        nombre: '',
        nombreComercial: '',        
        numCxc: 0,       
        numCxcPagadas: 0,
        ssoId: 0,
        telefono: '',
        totalPagos: 0,
        ventaId: 0
    });
    const [stateCxcs, setStateCxcs] = useState([]);

    const [statecxcidselected, setstatecxcidselected] = useState(0);
    const [stateimpselected, setstateimpselected] = useState(0);
    const [stateIdPayselected, setstateIdPayselected] = useState(0);

    const [stateNextPayment, setStateNextPayment] = useState({
        fechaVencimiento: new Date, id: 0, identificador: 0,notasCxc: "",
        numfactura: "0",pagos: 0,saldo: 0,valor: 0,
        ventaId: 0,
        visibleAlPago: false
    });

    const [stateSeenPopupFG, setstateSeenPopupFG] = useState(false);
    const [stateClientSatList, setStateClientSatList] = useState([]);

    const [stateClientSatSeleted, setStateClientSatSelected] = useState();

    const [stateECustomerFG, setstateECustomerFG] = useState('');

    const [stateResponseCfdiEmmitFG, setstateResponseCfdiEmmitFG] = useState('');
    
    useEffect(() => {
        cxcService.getByBuyertId(auth.id).then( res => {            
            const vta = res.data.find(f => f.ventaId === +id);
            //console.log(vta);
            setStateSaleSelected(vta);   
            
            cxcService.getByBusinessVentaDateD(id).then(res => {              
                setStateCxcs(res.data);            
                
                const nextPayment = res.data.find(f => f.visibleAlPago && vta.estatusVentaId == 1);
                if(nextPayment != undefined) {
                    setStateNextPayment(nextPayment);                
                } else {
                    setStateNextPayment({
                        fechaVencimiento: new Date, id: 0, identificador: 0,notasCxc: "",
                        numfactura: "0",pagos: 0,saldo: 0,valor: 0,
                        ventaId: 0,
                        visibleAlPago: false
                            })
                }                
            });   
        });
        
        clientSatService.GetAllBySecurityBuyerIdAsync(auth?.token, auth.id).then(response => {                 
            setStateClientSatList(response.data);           
        }); 
    }, []);

    const togglePop = (option) => {
        setstateSeenPopup(!stateSeenPopup);    
    };

    const onClickbtnPay = (e, cxcid, importe, idPay) => {
        e.preventDefault();       

        setstatecxcidselected(cxcid);
        setstateimpselected(importe);
        setstateIdPayselected(idPay);
        setstateSeenPopup(true);
    }

    const onClickbtnEmitReceive = (e, cxcid, importe, idPay) => {
        setstatecxcidselected(cxcid);
        setstateimpselected(importe);
        setstateIdPayselected(idPay);
        setstateSeenPopupFG(true);
    }

    const onClickDomEventbtnClosefrompopupFG = () => {
        setstateSeenPopupFG(false);
    }

    const onClickDomEventbtnEmitReceiveFG = async () => {

        if (stateClientSatSeleted != undefined) {

            const payloadAddCustomer = {
                businessId: stateSaleSelected.fGebusinessuuid,
                customer: {
                    name: stateClientSatSeleted.name,
                    taxid: stateClientSatSeleted.rfc,
                    alias: stateClientSatSeleted.alias,
                    email: stateClientSatSeleted.email,
                    phone: stateClientSatSeleted.phone,
                    taxregime: {
                        k: stateClientSatSeleted.regime
                    },
                    invoiceuse:{
                        k: stateClientSatSeleted.cfdiUse
                    },
                    address:{
                        main: {
                            street: stateClientSatSeleted.street,
                            suburb: {
                                k: stateClientSatSeleted.suburb
                            },
                            zip: stateClientSatSeleted.zip
                        }
                    }
                }
            };

            const responseFGAddCustomer = await facturaGreenService.postCustomerAddAsync(payloadAddCustomer);
            //console.log(responseFGAddCustomer);
            if(responseFGAddCustomer.isSuccess){
                setstateECustomerFG(responseFGAddCustomer.data.uuid);
            } else {
                setstateECustomerFG(responseFGAddCustomer.message);
            }        

            const payloadCfdiEmmit = {
                businessId: stateSaleSelected.fGebusinessuuid,
                data: {
                    cfdi: {
                        customer: {
                            uuid: stateECustomerFG
                        },
                        payment: {
                            form:{
                                k: "04"
                            },
                            method: {
                                k: "PUE"
                            }
                        },
                        items: [
                            {
                                uuid: stateSaleSelected.fGeproductuuid,
                                qty: 1,
                                price: {
                                    amount: stateimpselected
                                }
                            }
                        ],
                        observations: `Venta ${stateSaleSelected?.ventaId} - Cxc ${statecxcidselected}`
                    }                
                }
            };

            //console.log(payloadCfdiEmmit);
            const responseFGpayloCfdiEmmit = await facturaGreenService.postcfdiemmitAsync(payloadCfdiEmmit);      

            if(responseFGpayloCfdiEmmit.isSuccess) {
                var payloadCfdiEmmitForCxcApi = {
                    id:0,
                    cxcId: statecxcidselected,
                    ResponseFG: JSON.stringify(responseFGpayloCfdiEmmit)
                } 

                const responseCxcCfdiEmmitService = await CxcCfdiEmmitService.post(payloadCfdiEmmitForCxcApi);  

                swal("Emision de factura!", "factura creada con exito!", "success")
                .then((value) => {
                    setstateResponseCfdiEmmitFG(JSON.stringify(responseFGpayloCfdiEmmit.data.cfdi));    
                });  
            } else {
                swal("Emision de factura no exitosa!", "la factura no fue creada!", "warning")
                .then((value) => {
                    setstateResponseCfdiEmmitFG(JSON.stringify(responseFGpayloCfdiEmmit));    
                });
            }

        } else {

        }
    }

    const onChangeClientSatSeleted = (e) => {     

        const fiscalData = stateClientSatList.find(f => f.id == +e.target.value);
        setStateClientSatSelected(fiscalData);
       
    }

    return (
        <>
           <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <hr></hr>
                        <h1>Informaciòn de venta</h1>
                        <div style={{fontWeight: "bold"}}>
                            <div className='row'>
                                <div className='col'>   
                                    Venta : {stateSaleSelected?.ventaId}   <br></br>
                                    Fecha : {stateSaleSelected?.fechaVta} <br></br>
                                    Sucursal : {stateSaleSelected?.nombreComercial}  <br></br>
                                    Cliente : {stateSaleSelected?.nombre} <br></br>
                                    Descripciòn : {stateSaleSelected?.descripcion} 
                                </div>
                                <div className='col'>
                                    Monto:             {Common.currencyFormat(stateSaleSelected.montoVenta)} <br></br>
                                    Monto adelantado   {Common.currencyFormat(stateSaleSelected.montoAdelanto)} <br></br>
                                    Total de pagos     {Common.currencyFormat(stateSaleSelected.totalPagos)} <br></br>      
                                    Saldo              {Common.currencyFormat(stateSaleSelected.montoVenta - (stateSaleSelected.montoAdelanto + stateSaleSelected.totalPagos))}
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <h2>Proxima cuenta por pagar</h2>
                        <div className='row'>
                            <div className='alert alert-success' role="alert" style={{textAlign: 'center'}}>                 
                                   <p>Vencimiento: {moment(stateNextPayment.fechaVencimiento).format('MMMM DD YYYY')}</p>
                                    <p>Valor: {Common.currencyFormat(stateNextPayment.valor)}</p>
                                    <p>Pagos: {Common.currencyFormat(stateNextPayment.pagos)}</p>
                                    <p>Saldo: {Common.currencyFormat(stateNextPayment.saldo)}</p>
                                    {
                                        (stateNextPayment.visibleAlPago && stateSaleSelected.estatusVentaId == 1) 
                                        ? <button type="button" className="btn btn-warning" onClick={e => onClickbtnPay(e, stateNextPayment.id, stateNextPayment.valor, stateNextPayment.identificador)}>Realizar pago</button>
                                        : null
                                    }
                            </div>
                        </div>
                        <hr></hr>
                        <h2>Informaciòn de cuentas por pagar</h2>
                        <ul className="list-group">
                            {
                                stateCxcs && stateCxcs?.map(item =>
                                    <li className="list-group-item" key={item.id}>
                                        <div className='row'>
                                            <div className='col'>
                                                <p>Vencimiento: {moment(item.fechaVencimiento).format('MM/DD/YYYY')}</p>
                                                {/* <p>Factura: {item.numFactura}</p> */}
                                                <p>Valor: {Common.currencyFormat(item.valor)}</p>
                                                {/* <p>Notas: {item.notas}</p> */}
                                                <p>Pagos: {Common.currencyFormat(item.pagos)}</p>
                                                <p>Saldo: {Common.currencyFormat(item.saldo)}</p>
                                            </div>
                                            <div className='col'>
                                                <ul style={{ marginTop: "30px" }}>
                                                    {
                                                        item.cxcPagos.map(item1 => 
                                                            <li key={item1.cxcPagoId}>{moment(item1.fechaExpedicion).format('MM/DD/YYYY')} 
                                                            | {Common.currencyFormat(item1.monto)} | abono aplicado</li>    
                                                        )
                                                    }
                                                </ul>
                                                                                               
                                            </div>
                                            <div className='col' style={{marginTop: '30px', textAlign:'right'}}>
                                                {
                                                    (item.visibleAlPago && stateSaleSelected.estatusVentaId == 1) ? 
                                                        <button type="button" className="btn btn-warning" onClick={e => onClickbtnPay(e, item.id, item.valor, item.identificador)}>Realizar pago</button> :
                                                        null
                                                                                                          
                                                } 
                                                {
                                                    (stateSaleSelected.estatusVentaId == 1 && item.saldo == 0) ? 
                                                    <button type="button" className="btn btn-warning" onClick={e => onClickbtnEmitReceive(e, item.id, item.valor, item.identificador)}>Facturar pago</button> :
                                                        null  
                                                }
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                            
                        </ul>                       
                    </div>
                </div>
            </div>
            {stateSeenPopup ? <div> <PopUpcodeBarPay                                 
                                    cxcid={ statecxcidselected }   
                                    imp={stateimpselected}           
                                    idpay={stateIdPayselected}              
                                    togglePop={ togglePop } 
                                /> 
                            </div> : null}       
            {stateSeenPopupFG ? <PopUpGetFiscalReceive 
                stateClientSatList={stateClientSatList}
                stateSaleSelected={stateSaleSelected}
                onClickDomEventbtnClosefrompopupFG={onClickDomEventbtnClosefrompopupFG}
                onClickDomEventbtnEmitReceiveFG={onClickDomEventbtnEmitReceiveFG}
                onChangeClientSatSeleted={onChangeClientSatSeleted}
                stateECustomerFG={stateECustomerFG}
                stateResponseCfdiEmmitFG={stateResponseCfdiEmmitFG}
            /> : null}
        </>
    )
}

export default SaleCxcEdit;
import axios from 'axios';
import Config from "../../config.json";

//const API_BASE_URL = `${process.env.REACT_APP_APIREST_CXC_URL}/Cxc`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_CXC_URL}/v1/CxcCfdiEmmit`;

const post = async (auth_token, values) => {        

    const response = await axios.post(  `${API_BASE_URL}/InsertAsync`, values );
    const data = await response.data;   

    return data;
}


export const CxcCfdiEmmitService = {   
    post
}
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import { cxcService } from '../../Services/Cxc/cxcService';
import { salesService } from '../../Services/salesService';
import { commerceService } from '../../Services/commerceService';
import { clibUserService } from '../../Services/Fintechs/clibUserService';
import { clibService } from '../../Services/Fintechs/clibService';
import { boromirUserService } from '../../Services/Boromir/boromirUserService';
import { boromirService } from '../../Services/Boromir/boromirService';

import { Common }from '../../Utils/Common';

import swal from 'sweetalert';

const PaymentByClib = () => {
    const token = Common.getToken();
    const user = Common.getUser();    

    const { id, cxcid } = useParams();

    const iframeRefChkclip = useRef(null);

    const navigate = useNavigate();

    const [stateIframeVisible, setStateIframeVisible] = useState(true);

    const [statecurrentBusinessid, setstatecurrentBusinessid] = useState(0);

    const [stateNextPayment, setStateNextPayment] = useState({
        fechaVencimiento: new Date, id: 0, identificador: 0,notasCxc: "",
        numfactura: "0",pagos: 0,saldo: 0,valor: 0,
        ventaId: 0,
        visibleAlPago: false
            });
    
    useEffect(() => {
        CleanClipLocalStorageInfo();

        cxcService.getByIdWithBalance(cxcid).then( res => {     
                       
            setStateNextPayment(res.data);   
            
            SetLocalstorageVariables(res.data.ventaId);            
        });
    }, []);

    const SetLocalstorageVariables = async (saleid) => {        
        const salesResponse = await salesService.getAsync(token, saleid);
        //console.log(salesResponse);
        const busId = salesResponse.data.businessId;
        setstatecurrentBusinessid(salesResponse.data.businessId);

        const commerceResponse = await commerceService.getAsync(token, busId); 

        console.log(JSON.stringify(commerceResponse));

        if(commerceResponse.data.clipApiKey == null) {
            swal("Falta de configuración!", "No se pueden realizar pagos con tarjeta!", "warning")
            .then((value) => {
                CleanClipLocalStorageInfo();
                navigate(`/cxcList`, { replace: true });
            });
        } else {
            localStorage.setItem("Publickey", commerceResponse.data.clipApiKey);        

            iframeRefChkclip.current.src = "/CheckoutclibV1.html";
            //localStorage.setItem("Publickey", "0940fc95-76a0-4ba5-8db0-00d1dd854f89");     
        }           
    }

    const onClickBtnforCreatePaymentMethod = async (e) => {
        e.preventDefault();

        setStateIframeVisible(true);        

        const cardTokenClib = JSON.parse(localStorage.getItem("cardTokenClib"));

        //console.log("Card token!", cardTokenClib);

        if (cardTokenClib.id != ''){
            
            setStateIframeVisible(false);

            const tokenClibfintech = await GetTokenFromApFintechs();

            //console.log(tokenClibfintech);

            const payload =  {
                amount: stateNextPayment.saldo + 15,
                currency: 'MXN',
                description: "Test Transparent checkout SDK",
                payment_method: {
                   token: cardTokenClib.id
                },
                customer: {
                    email: user.user,
                    phone: user.phone,
                    address: {
                        number: user.numberAddress,
                        postal_code: user.postalCodeAddress,        
                        street: user.streetAddress
                    }
                },
                axifyBusinnessId: statecurrentBusinessid
            };          
            
            //console.log(JSON.stringify(tokenClibfintech));
            //console.log(JSON.stringify(payload));

            const responsePaymentMethod = await clibService.createPaymentsAsync(tokenClibfintech, payload);
                        
            //console.log(responsePaymentMethod);

            if(responsePaymentMethod.data.status == 'rejected') {
                swal("Transacción rechazada!", "Favor de reintentar el pago!", "warning")
                .then((value) => {
                    CleanClipLocalStorageInfo();
                    navigate(`/cxcList`, { replace: true });
                }); 
            } else {
                if(responsePaymentMethod.data.status_detail.code == 'PE-3DS01') {
                    localStorage.setItem("pending_action.url", responsePaymentMethod.data.pending_action.url); 
                    navigate(`/threeDSByclib/${id}/${cxcid}/${statecurrentBusinessid}/${stateNextPayment.saldo}`, { replace: true });                  
                } else {
                    await ApplyPaymentMethod(id, responsePaymentMethod.data.id, statecurrentBusinessid, stateNextPayment.saldo);                  
                }
            }                   
        } else {
            swal("Error de tarjeta!", "fallo la generación de token de tarjeta!", "warning")
                .then((value) => {
                    CleanClipLocalStorageInfo();
                    navigate(`/cxcList`, { replace: true });
                });            
        }       
    }

    const GetTokenFromApFintechs = async () => {
        const userForAuthenticate = {
            "Id": 0,
            "User": "soporte@axify.mx",
            "Password": "Axify2023",
            "Token": ""
        };
        const responseclibUser = await clibUserService.Authentication(userForAuthenticate);
        
        //console.log(responseclibUser);
        
        const tokenclib = responseclibUser.data.data.token;

        return tokenclib;
    }  

    const CleanClipLocalStorageInfo = () => {
        localStorage.setItem("Publickey", '');
        localStorage.setItem("cardTokenClib", '');
        localStorage.setItem("pending_action.url", '');
    }

    const ApplyPaymentMethod = async (idP, paymentIdClipP, bussinessidP, saldoP) => {
        const tokenClibfintech = await GetTokenFromApFintechs();
        const responsePaymentMethod = await clibService.getPaymentsAsync(tokenClibfintech, paymentIdClipP, bussinessidP);

        if(responsePaymentMethod.data.status == 'approved' && responsePaymentMethod.data.status_detail.code == 'AP-PAI01') {
            const userForAuthenticate = {
                "User": "soporte@axify.mx",
                "Password": "Axify2023"
            };

            let responseUserBoromir = await boromirUserService.postAuthenticate(userForAuthenticate);

            const tokentoboromir = responseUserBoromir.token;
            //console.log(tokentoboromir);

            const requestBoromirPayment = {
                "referencia": idP,
                "importe": saldoP,
                "compañia": '0',
                "sucursal": bussinessidP.toString(),
                "idtransaccion" : 'Clip|' + paymentIdClipP
            };

            //console.log(JSON.stringify(requestBoromirPayment));

            let responseBoromirPaymentService = await boromirService.postProductPayment(tokentoboromir, requestBoromirPayment);
                    
            //console.log(responseBoromirPaymentService);

            if (responseBoromirPaymentService.error == 0){
                swal("Transacción realizada!", "Gracias por su pago!", "success")
                .then((value) => {
                    CleanClipLocalStorageInfo();
                    navigate(`/cxcList`, { replace: true });
                });
    
            } else {
                swal("Abono no aplicado en axify!", "favor de contactar a soporte de axify!", "warning")
                .then((value) => {
                    CleanClipLocalStorageInfo();
                    navigate(`/cxcList`, { replace: true });
                });    
            }

        } else {
            swal("Transaccion rechazada!", "Favor de reintentar el pago!", "warning")
            .then((value) => {
                CleanClipLocalStorageInfo();
                navigate(`/cxcList`, { replace: true });
            });
        }
    }

    return (
       <>
        <div className="container">
            <div className='row'>
                <div className='col'></div>
                <div className='col' style={{textAlign: 'center'}}>
                    <h3>Pago con tarjeta</h3>
                </div>
                <div className='col'></div>
            </div>            
            <div className="row">
                <div className="col"></div>
                <div className="col">
                    <div className='alert alert-success' role="alert" style={{textAlign: 'center'}}>                 
                            <p>Vencimiento: {moment(stateNextPayment.fechaVencimiento).format('MMMM DD YYYY')}</p>
                            <p>Valor: {Common.currencyFormat(stateNextPayment.valor)}</p>
                            <p>Pagos: {Common.currencyFormat(stateNextPayment.pagos)}</p>
                            <p>Saldo: {Common.currencyFormat(stateNextPayment.saldo)}</p>    
                            <p>Transacción: $15.00</p>                       
                    </div>
                </div>
                <div className="col"></div>
            </div>          
            <div className='row'>
                <div className='col'></div>
                <div className='col'>
                    <legend><img src="/assets/img/cliblogo.jpg" alt="Logo Clib" style={{ "width": "100px", "padding": "2px", "marginLeft": "40%" }}/></legend>                    
                </div>
                <div className='col'></div>
            </div>
            <div className='row' style={{ display: (stateIframeVisible ? 'block' : 'none')}}>
                <div className='col'></div>
                <div className='col'>
                    <div className="row">
                        <div className="col"></div>
                        <div className="col">
                            {/* <iframe ref={iframeRefChkclip} title="CheckoutclibV1" src="/CheckoutclibV1.html" style={{border:"none", width:"500px", height:"400px"}}></iframe>  */}
                            <iframe ref={iframeRefChkclip} title="CheckoutclibV1" src="" style={{border:"none", width:"500px", height:"400px"}}></iframe> 
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className='col'></div>
            </div>           
            <div className="row">                   
                <div className='col'>
                    <button id="btnForCreatePaymentMethod" onClick={e => onClickBtnforCreatePaymentMethod(e)} style={{ display: "none" }}>Process</button>                    
                </div>             
            </div>
        </div>            
       </>         
    )

}

export default PaymentByClib;
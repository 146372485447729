import axios from 'axios';
import Config from "../config.json";

const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/fiscalregime`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
};    

const GetByIdAsync = async (auth_token, id) => { 
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetfiscalRegimeByIdAsync/${id}`, CONFIG_REQUEST);
    const data = await response.data;

    return data;
}

const GetAllFisicaAsync = async (auth_token) => { 
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetAllFisicaAsync`, CONFIG_REQUEST);
    const data = await response.data;

    return data;
}

const GetAllMoralAsync = async (auth_token) => { 
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetAllMoralAsync`, CONFIG_REQUEST);
    const data = await response.data;

    return data;
}


export const fiscalRegimeService = {
    GetAllFisicaAsync,
    GetAllMoralAsync,
    GetByIdAsync
}